require('fullpage.js/vendors/scrolloverflow.min');
import fullPage from 'fullpage.js'
import {gsap} from "gsap";
import "./menu-burger";

document.querySelectorAll('#burger-wrapper, #menu-overlay').forEach((item) => {

    item.addEventListener('click', function (e) {
        e.preventDefault();
        openMenu();
    });
});
const openMenu = () => {
    if (!document.body.classList.contains('menu-open')) {
        document.querySelector('#menu').style.display = 'flex';
        document.querySelector('#menu-overlay').style.display = 'block';
    }
    setTimeout(() => {
        document.body.classList.toggle('menu-open');
        setTimeout(() => {
            if (!document.body.classList.contains('menu-open')) {
                document.querySelector('#menu').style.display = 'none';
                document.querySelector('#menu-overlay').style.display = 'none';
            }
        }, 1000);
    }, 100);
};

import {CustomEase} from "gsap/CustomEase";


function addClass(s, n) {
    document.querySelectorAll(s).forEach((item) => {
        item.classList.add(n)
    })
}

function removeClass(s, n) {
    document.querySelectorAll(s).forEach((item) => {
        item.classList.remove(n)
    })
}

function toggleClass(s, n) {
    document.querySelectorAll(s).forEach((item) => {
        item.classList.toggle(n)
    })
}

document.addEventListener("DOMContentLoaded", function () {
    gsap.registerPlugin(CustomEase);

    let myFullpage = new fullPage('#fullpage', {
        scrollingSpeed: 5,
        scrollOverflow: true,
        scrollOverflowOptions: {
            preventDefaultException: false,
            click: false
        },
        touchSensitivity: 15,
        animateAnchor: false,


//events
        onLeave: function (origin, destination, direction) {

            fullpage_api.setAllowScrolling(false);


            /*
                        $.fn.fullpage.setMouseWheelScrolling(false);
                        $.fn.fullpage.setAllowScrolling(false);
            */

            //console.log(origin, destination, direction);
            if (origin.anchor === 'section-praplan' && direction === 'down') {
                myFullpage.setScrollingSpeed(700);
            }


            let tl = gsap.timeline({paused: true})

            /***
             * origin
             */
            switch (origin.anchor) {
                case 'section-home':
                    tl.fromTo(document.querySelector('#home .inner'), {opacity: 1}, {
                        opacity: 0,
                        duration: 1
                    });
                    break;

                case 'section-groupe':
                    tl
                        .fromTo("#groupe p", {y: 0, opacity: 1}, {y: 40, opacity: 0, duration: 1.5}, 0)
                        .fromTo("#groupe .separateur-logo .trait", {opacity: 1, height: 60}, {
                            opacity: 0,
                            height: 0,
                            duration: .5
                        }, ">-1")
                        .fromTo("#groupe .visuel", {y: 0, opacity: 1}, {y: -10, opacity: 0, duration: .5}, ">-0.5")
                        .fromTo("#groupe .title-h1", {x: 0, opacity: 1}, {
                            x: -50,
                            opacity: 0,
                            duration: .5,
                        }, ">-1")
                        .fromTo("#groupe .separateur-only", {opacity: 1}, {
                            opacity: 0,
                            duration: .5,
                        }, ">-1.2")
                    break;

                case 'section-activites':

                    tl.fromTo(document.querySelectorAll('#liste-activites, #activites .separateur-only'), {opacity: 1}, {
                        opacity: 0,
                        duration: 1
                    });
                    break;

                case 'section-administration':
                case 'section-immobilier':
                case 'section-sport':
                case 'section-evenementiel':
                case 'section-industriel':
                case 'section-alimentaire':

                    tl
                        .fromTo("[data-anchor='" + origin.anchor + "'] .inner, [data-anchor='" + origin.anchor + "'] .separateur-only", {opacity: 1}, {
                            opacity: 0,
                            duration: .5,
                        }, 0)

                    if (direction !== 'down') {
                        tl.fromTo(".bg-" + origin.anchor, {scaleY: 1}, {
                            scaleY: {scaleY: 0},
                            duration: 1,
                        })
                    }

                    break;

                case 'section-associes':

                    tl.fromTo(document.querySelectorAll('#liste-associes, #associes .separateur-only'), {opacity: 1}, {
                        opacity: 0,
                        duration: 1
                    });
                    break;
                case 'section-dracos':
                case 'section-emery':
                case 'section-fattebert':
                case 'section-praplan':

                    if (origin.anchor === 'section-praplan' && direction === 'down') {
                        //console.log("condition a ne pas jouer l animation");
                    } else {
                        tl
                            .fromTo("[data-anchor='" + origin.anchor + "'] .inner, [data-anchor='" + origin.anchor + "'] .separateur-only", {
                                opacity: 1,
                                webkitFilter: "blur(0px)"
                            }, {
                                opacity: 0,
                                webkitFilter: "blur(20px)",
                                duration: .5,
                            }, 0)
                    }

                    break;


            }

            /**
             * Destination
             */
            switch (destination.anchor) {
                case 'section-home':
                    tl
                        .to('#background-fade', {backgroundColor: "#FFF"})
                        .call(removeClass, ['body', "theme-dark"])
                        .fromTo(document.querySelector('#home .inner'), {opacity: 0}, {
                            opacity: 1,
                            duration: 1,
                            //delay: 1.7
                        });
                    break;
                case 'section-groupe':
                    tl
                        .set("#groupe .separateur-logo .trait", {opacity: 1})
                        .to('#background-fade', {backgroundColor: "#FFF"})
                        .call(removeClass, ['body', "theme-dark"])
                        .fromTo("#groupe .title-h1", {x: -100, opacity: 0}, {
                            x: 0,
                            opacity: 1,
                            duration: 1.5,
                            //delay: 1.3
                        })
                        .fromTo("#groupe .visuel", {y: 10, opacity: 0}, {y: 0, opacity: 1, duration: 1.5}, ">-0.5")
                        .fromTo("#groupe .separateur-logo .trait", {height: 0}, {height: 60, duration: 1.5}, ">-1");

                    document.querySelectorAll("#groupe p").forEach((item) => {
                        tl
                            .fromTo(item, {y: -40, opacity: 0}, {y: 0, opacity: 1, duration: 1.5}, ">-1")
                    });

                    tl
                        .fromTo("#groupe .separateur-only", {opacity: 0}, {
                            opacity: 1,
                            duration: .5,
                        }, ">-1");


                    break;
                case 'section-activites':
                    tl
                        .to('#background-fade', {backgroundColor: "#FFF"})
                        .call(removeClass, ['body', "theme-dark"])
                        .fromTo(document.querySelectorAll('#liste-activites'), {opacity: 0}, {
                            opacity: 1,
                            duration: 1,
                            //delay: 1.6
                        })
                        .fromTo("#block-line-1", {scaleY: 0}, {scaleY: 1, duration: 1.5}, ">-1");

                    document.querySelectorAll("#activites .items-listing a").forEach((item) => {
                        tl
                            .fromTo(item, {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            }, ">-.7")
                    });
                    tl.fromTo("#block-line-2", {scaleY: 0}, {scaleY: 1, duration: 1.5}, ">-1")
                        .fromTo("#activites .separateur-only", {opacity: 0}, {
                            opacity: 1,
                            duration: .5
                        }, ">-.7");

                    break;

                case 'section-administration':
                case 'section-immobilier':
                case 'section-sport':
                case 'section-evenementiel':
                case 'section-industriel':
                case 'section-alimentaire':

                    tl
                        /*.fromTo(".bg-" + destination.anchor + "", {scaleY: direction === 'up' ? 1 : 0}, {
                            scaleY: 1,
                            duration: direction === 'up' ? 0.1 : .7,
                            //delay: .2
                        })*/
                        .to('#background-fade', {backgroundColor: destination.item.dataset.bgColor})
                        .call(destination.item.dataset.bgColor === '#000000' ? addClass : removeClass, ['body', "theme-dark"])

                        .fromTo("[data-anchor='" + destination.anchor + "'] .inner", {opacity: 0}, {
                            opacity: 1,
                            duration: 0.1,
                        })
                        .fromTo("[data-anchor='" + destination.anchor + "'] .title-2", {y: -20, opacity: 0}, {
                            y: 0,
                            opacity: 1,
                            duration: 1.5,
                        }, '>-.4')
                        .fromTo("[data-anchor='" + destination.anchor + "'] .title-2 span", {scaleX: 0}, {
                            scaleX: 1,
                            duration: 1.5,
                        }, '>-1')


                        .fromTo("[data-anchor='" + destination.anchor + "'] .bloc-visuel .bg", {scaleX: 1}, {
                            scaleX: 0,
                            duration: .5
                        }, ">-1")

                        .fromTo("[data-anchor='" + destination.anchor + "'] .bloc-visuel .big-visuel", {scale: 1.5}, {
                            scale: 1,
                            duration: 1.5
                        }, ">-1")
                    /* .fromTo("[data-anchor='" + destination.anchor + "'] .container", {opacity: 0}, {
                         opacity: 1,
                         duration: 1.5
                     }, ">-1")*/

                    document.querySelectorAll("[data-anchor='" + destination.anchor + "'] .container p").forEach((item) => {
                        tl.fromTo(item, {y: -30, opacity: 0}, {y: 0, opacity: 1, duration: 1.5}, ">-.7")
                    });

                    tl.fromTo("[data-anchor='" + destination.anchor + "'] .container .clients", {opacity: 0}, {
                        opacity: 1,
                        duration: 1.5
                    }, ">-.7");

                    tl.fromTo("[data-anchor='" + destination.anchor + "'] .separateur-only", {opacity: 0}, {
                        opacity: 1,
                        duration: .5
                    }, ">-.7");


                    break;

                case 'section-associes':

                    ///
                    tl
                        .to('#background-fade', {backgroundColor: "#FFF"})
                        .call(removeClass, ['body', "theme-dark"])
                        .fromTo(document.querySelectorAll('#liste-associes'), {opacity: 0}, {
                            opacity: 1,
                            duration: 1,
                            //delay: 1.6
                        })
                        .fromTo("#block-line-3", {scaleY: 0}, {scaleY: 1, duration: 1.5}, ">-1");

                    document.querySelectorAll("#associes .items-listing a").forEach((item) => {
                        tl
                            .fromTo(item, {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            }, ">-.7")
                    });
                    tl.fromTo("#block-line-4", {scaleY: 0}, {scaleY: 1, duration: 1.5}, ">-1")
                        .fromTo("#associes .separateur-only", {opacity: 0}, {
                            opacity: 1,
                            duration: .5
                        }, ">-.7");

                    ///

                    break;
                case 'section-dracos':
                case 'section-emery':
                case 'section-fattebert':
                case 'section-praplan':
                    if (destination.anchor === 'section-praplan' && direction === 'up') {
                        setTimeout(() => {
                            myFullpage.setScrollingSpeed(5);
                        }, 700);
                    } else {
                        tl
                            .to('#background-fade', {backgroundColor: "#FFF"})
                            .call(removeClass, ['body', "theme-dark"])
                            .fromTo("[data-anchor='" + destination.anchor + "'] .inner", {
                                opacity: 0,
                                webkitFilter: "blur(0px)"
                            }, {
                                webkitFilter: "blur(0px)",
                                opacity: 1,
                                duration: 0.1,
                            })


                            .fromTo("[data-anchor='" + destination.anchor + "'] .big-visuel", {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            })
                            .fromTo("[data-anchor='" + destination.anchor + "'] .word:nth-child(1)", {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            }, ">-.7")
                            .fromTo("[data-anchor='" + destination.anchor + "'] .word:nth-child(2)", {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            }, ">-.7")
                            .fromTo("[data-anchor='" + destination.anchor + "'] .word:nth-child(3)", {
                                scale: 1.4,
                                opacity: 0,
                                webkitFilter: "blur(20px)"
                            }, {
                                scale: 1,
                                opacity: 1,
                                webkitFilter: "blur(0px)",
                                duration: 1,
                            }, ">-.7")
                            .fromTo("[data-anchor='" + destination.anchor + "'] .title-h3", {y: -40, opacity: 0}, {
                                y: 0,
                                opacity: 1,
                                duration: 1.5,
                                //delay: 1.3
                            }, ">-.7")

                        document.querySelectorAll("[data-anchor='" + destination.anchor + "'] p").forEach((item) => {
                            tl.fromTo(item, {y: -30, opacity: 0}, {y: 0, opacity: 1, duration: 1.5}, ">-.7")
                        });
                        tl.fromTo("[data-anchor='" + destination.anchor + "'] .separateur-only", {opacity: 0}, {
                            opacity: 1,
                            duration: .5
                        }, ">-.7");
                        break;


                    }
            }

            tl.play();

            tl.eventCallback("onComplete", function () {
                fullpage_api.setAllowScrolling(true);
            });

            if (tl.duration() === 0) {
                fullpage_api.setAllowScrolling(true);
            }

        }
    });

    /**
     * Click Menu
     */
    document.querySelectorAll('#menu a').forEach((item) => {

        item.addEventListener('click', function (e) {
            e.preventDefault();

            fullpage_api.moveTo(item.dataset.goto);
            setTimeout(() => {
                openMenu();
            }, 500);

        });
    });

    /**
     * Click Associes
     */
    document.querySelectorAll('#liste-associes a').forEach((item) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            fullpage_api.moveTo(item.dataset.goto);
        });
    });

    /**
     * Click activites
     */
    document.querySelectorAll('#liste-activites a').forEach((item) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            fullpage_api.moveTo(item.dataset.goto);
        });
    });

    /**
     * Click separateur icon
     */
    document.querySelectorAll('.separateur-only img, #home .separateur img').forEach((item) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            fullpage_api.moveSectionDown();
        });
    });


});
